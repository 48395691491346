import axios from "axios";
import { errorToast } from "./customToast";
import history from "./history";
import { nullString } from "./formatting";
import { handleInterpolatorRequest } from "./tokenValidator";

export const url = `${process.env.REACT_APP_API_URL}`;
export const splash =
  "https://react.semantic-ui.com/images/wireframe/square-image.png";
export const imgUrl = url + "/uploads";

export const baseUrl = url + "/api";

const wsPort = process.env.REACT_APP_WEBSOCKET_PORT
  ? process.env.REACT_APP_WEBSOCKET_PORT
  : "6004";
export const wsUrl = url + `:${wsPort}`;

let canShowError = true;
const changeError = (b) => {
  canShowError = b;
};

axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use(handleInterpolatorRequest, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (res) => {
    if (res.data.status) {
      if (res.data.status === 403) history.push("/error-403");
      if (res.data.status === 401) history.push("/error-401");
    }
    return Promise.resolve(res);
  },
  (err) => {
    if (!err.response)
      if (canShowError)
        errorToast("Network Error", {
          onOpen: () => {
            changeError(false);
          },
          onClose: () => {
            changeError(true);
          },
        });
      else {
        if (err.response.status === 500)
          if (canShowError)
            errorToast("Connecting to server error...", {
              onOpen: () => {
                changeError(false);
              },
              onClose: () => {
                changeError(true);
              },
            });
          else if (err.response.status === 403) history.go("/error-404");
      }
    return Promise.reject(err);
  }
);

export const changeHeaders = (param, value) => {
  axios.defaults.headers.common[param] = value;
};

export const postMethod = (url, data, config) => {
  settingToken();
  return axios.post(url, data, config);
};

export const getMethod = (url, config) => {
  settingToken();
  return axios.get(url, config);
};

export const deleteMethod = (url, config) => {
  settingToken();
  return axios.delete(url, config);
};

export const putMethod = (url, data, config) => {
  settingToken();
  return axios.put(url, data, config);
};

const settingToken = () => {
  let token = localStorage.getItem("token");
  let lang = localStorage.getItem("lang");
  let complex_id = localStorage.getItem("complex_id");
  let checkout_session_id =  localStorage.getItem("session_id");

  axios.defaults.headers.common["Authorization"] =
    token !== null && token !== ""
      ? `Bearer ${localStorage.getItem("token")}`
      : "";
  axios.defaults.headers.common["Accept-Language"] = lang ? lang : "";
  axios.defaults.headers.common["Complex"] = complex_id ? complex_id : "";
  if (checkout_session_id)
    axios.defaults.headers.common["Checkout"] = checkout_session_id
      ? checkout_session_id
      : "";
};

// TODO Implement the api when its ready
export const isTokenValid = (store) => (next) => (action) => {
  try {
    // check for token validity
    let date = new Date(store.user.expirationDate);
    if (date - new Date() < 0) {
      // call api for refreshing the token
    }

    next(action);
  } catch (err) {
    //
  }
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      // @ts-ignore
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const removeSecond = (hour) => {
  let h = nullString(hour);
  let all = h.split(":");
  if (all.length >= 3) return all[0] + ":" + all[1];

  return h;
};

export const switchLanguageInHtml = (lang) => {
  if (lang === "ar") {
    document.querySelector("html").setAttribute("dir", "rtl");
  } else {
    document.querySelector("html").setAttribute("dir", "ltr");
  }
  document.querySelector("html").setAttribute("lang", lang);
};
