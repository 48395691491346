import { nullString, nullStringNumber, reversePhoneNumber } from "./formatting";
import moment from "moment";
import paymentMethodService from "../services/paymentMethodService";
import { getHT } from "@/helpers/caisse/tvaCalcul";
import { DEFAULT_COUNTRY_CODE } from "../utils/countryCode";

export let player = {
  user_id: "",
  contact_id: "",
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  country_code: DEFAULT_COUNTRY_CODE,
  sensor_id: "",
  team_id: "",
  discount_id: "",

  removable: false,
  search: false,
  sensor: false,
  disabled: false,
  payed: false
};

export let game = {
  reservation_id: "",
  date: "",
  start_hour: "",
  end_hour: "",
  removable: true,
  hasTicket: false
};

export const reservationRequest = (data) => {
  let user = { ...data.information.user };
  // formatting advance
  let advance_ticket = data.information.advance_ticket;
  try {
    advance_ticket.user_id = user.user_id;
    advance_ticket.amount = data.information.advance
      ? parseFloat(data.information.advance).toFixed(2)
      : "";
    advance_ticket.date = data.information.date;
    advance_ticket.hour = data.information.start_hour;

    if (advance_ticket.articles && advance_ticket.articles.length > 0) {
      advance_ticket.articles = advance_ticket.articles.map((item, index) => {
        return {
          article_id: item.id,
          amount: (parseFloat(item.total_price) * parseFloat(item.article_nb)).toFixed(2),
          payed_amount: (parseFloat(item.total_price) * parseFloat(item.article_nb)).toFixed(2),
          article_nb: item.article_nb,
          // status: item.status,
          amount_ht: item.amount_ht,
          vat_amount: item.vat_amount,
          vat_id: item.vat_id,
          status: "payed"
        };
      });
    }

    if (data.information.advance_ticket && data.information.advance_ticket.articles && data.information.advance_ticket.articles.length > 0) {
      //articles
      advance_ticket.payments.articles[0].article_id = data.information.advance_ticket.article_id;
      advance_ticket.payments.articles[0].amount = parseFloat(data.information.advance).toFixed(2);
      advance_ticket.payments.articles[0].amount_ht = parseFloat(
        data.information.advance_ticket.articles[0].amount_ht
      ).toFixed(3);
      advance_ticket.payments.articles[0].vat_amount = parseFloat(
        data.information.advance_ticket.articles[0].vat_amount
      ).toFixed(3);
      advance_ticket.payments.articles[0].vat_id = data.information.advance_ticket.articles[0].vat_id;
      advance_ticket.payments.articles[0].payment_type_id =
        data.information.advance_ticket.payments.types[0].payment_type_id;
      // credit  setting up user id just in case
      if (advance_ticket.payments.articles[0].hasOwnProperty("credit_user_id")) {
        advance_ticket.payments.articles[0].credit_user_id = user.user_id;
      }

      if (advance_ticket.payments.articles[0].hasOwnProperty("credit_detail")) {
        advance_ticket.payments.articles[0].credit_detail.user_id = user.user_id;
      }
    }

  } catch (e) {
    // in case the advance_ticket doesnt exist
  }

  let formattedData = {
    complex_id: localStorage.getItem("complex_id"),
    leisure_ids: data.leisure_ids,
    user: {
      user_id: user.user_id,
      contact_id: user.contact_id ? user.contact_id : "",
      [user.contact_id ? "first_name" : "firstName"]: user.firstName,
      [user.contact_id ? "last_name" : "lastName"]: user.lastName,
      birthdate: user.birthdate ? user.birthdate : "",
      email: user.email,
      mobile: reversePhoneNumber(user.mobile),
      country_code: user.mobile ? user.country_code : "",
      company: user.company,
      avatar: user.avatar,
      join_user_id: user.join_user_id,
      join_complex_id: user.join_complex_id
    },
    reservation: {
      old_advance_id: data.old_advance_id,
      owner_id: data.information.owner_id,
      activity_id: data.information.activity_id,
      terrain_id: data.information.terrain_id,
      competition: data.competition,
      duration_id: data.information.duration_id,
      is_ticket: data.information.is_ticket,
      article_id: data.information.hasOwnProperty("article_id")
        ? data.information.article_id
        : null,
      //article_id: null,

      date: data.information.date,
      start_hour: data.information.start_hour,
      end_hour: data.information.end_hour,
      infos: data.information.info,
      hour_type_id: data.information.hour_type_id,
      price: data.information.price,

      recurrence: data.information.recurrence,
      recurrence_days: data.information.recurrence_days,
      choice_recurrence: data.information.choice_recurrence,
      start_date_rec: data.information.start_date_rec,
      end_date_rec: data.information.end_date_rec,

      conclusion: data.information.conclusion,
      cdn_video: data.information.cdn_video,

      games_nb: nullString(data.information.games_nb),
      games: data.information.games,
      is_leisure: data.information.is_leisure,
      is_advance: data.information.is_advance,
      advance_id: data.information.advance_id,
      advance: data.information.advance,
      advance_ticket: data.information.advance_id ? null : advance_ticket,

      participants: []
    }
  };

  if (!user.contact_id) delete formattedData.user.contact_id;

  let participants = [];
  let { equipe1, equipe2 } = data.participants;
  for (let i = 0; i < equipe1.players.length; i++) {
    let newPlayer = equipe1.players[i];
    if (
      JSON.stringify(newPlayer) !== JSON.stringify(player) &&
      (newPlayer.email || newPlayer.mobile)
    ) {
      delete newPlayer.removable;
      delete newPlayer.search;
      delete newPlayer.sensor;
      delete newPlayer.disabled;
      newPlayer.team_id = equipe1.id === 0 ? "" : equipe1.id;
      let pl = {
        user_id: newPlayer.user_id,
        contact_id: newPlayer.contact_id ? newPlayer.contact_id : "",
        [newPlayer.contact_id ? "first_name" : "firstName"]: newPlayer.firstName,
        [newPlayer.contact_id ? "last_name" : "lastName"]: newPlayer.lastName,
        email: newPlayer.email,
        country_code: newPlayer.mobile ? newPlayer.country_code : "",
        mobile: reversePhoneNumber(newPlayer.mobile),
        sensor_id: newPlayer.sensor_id,
        team_id: newPlayer.team_id,
        discount_id: newPlayer.discount_id
      };
      //if(!pl.contact_id) delete pl.contact_id;
      participants.push(pl);
    }
  }

  for (let i = 0; i < equipe2.players.length; i++) {
    let newPlayer = equipe2.players[i];
    if (
      JSON.stringify(newPlayer) !== JSON.stringify(player) &&
      (newPlayer.email || newPlayer.mobile)
    ) {
      delete newPlayer.removable;
      delete newPlayer.search;
      delete newPlayer.sensor;
      delete newPlayer.disabled;
      newPlayer.team_id = equipe2.id === 0 ? "" : equipe2.id;
      participants.push({
        user_id: newPlayer.user_id,
        contact_id: newPlayer.contact_id,
        [newPlayer.contact_id ? "first_name" : "firstName"]: newPlayer.firstName,
        [newPlayer.contact_id ? "last_name" : "lastName"]: newPlayer.lastName,
        email: newPlayer.email,
        country_code: newPlayer.mobile ? newPlayer.country_code : "",
        mobile: reversePhoneNumber(newPlayer.mobile),
        sensor_id: newPlayer.sensor_id,
        team_id: newPlayer.team_id,
        discount_id: newPlayer.discount_id
      });
    }
  }

  if (data.information.reservation_id)
    formattedData.reservation.id = data.information.reservation_id;

  formattedData.reservation.participants = participants;

  if (formattedData.reservation.competition.competition_id || data.isMatch) {
    if (!data.isMatch) formattedData.reservation.competition.competition_day_date = data.date;

    formattedData.reservation.competition.team_a_id = data.participants.equipe1.id;
    formattedData.reservation.competition.team_b_id = data.participants.equipe2.id;
  } else if (!data.isMatch) {
    formattedData.reservation.competition.team_a_id = data.participants.equipe1.id;
    formattedData.reservation.competition.team_b_id = data.participants.equipe2.id;
  }


  // if (!data.information.is_player)
  //   formattedData.reservation.competition.price_per_participant = !formattedData.reservation.competition.participants_nb ? (nullStringNumber(data.information.price) / formattedData.reservation.competition.participants_nb).toFixed(2) : 0;

  return formattedData;
};

export const reservationResponse = (data, response) => {
  let defaultParticipantsNb = response.match && response.match.participants_nb ? response.activity && response.activity.is_match === 1 ? parseInt(parseInt(parseInt(response.match.participants_nb) <= 1 ? 2 : response.match.participants_nb) / 2) : response.match.participants_nb : 5;
  let filterResult = { ...data };

  let { information } = filterResult;

  if (response.id) information.reservation_id = response.id;
  else information.reservation_id = null;

  if (!response.contact_id && response.user)
    information.user = {
      user_id: response.user.id,
      contact_id: null,
      firstName: response.user.firstName,
      lastName: response.user.lastName,
      birthdate: response.user && response.user.birthdate ? response.user.birthdate : "",
      email: response.user.email,
      lastEmail: response.user.email,
      country_code: response.user.mobile ? response.user.country_code : "",
      mobile: response.user.mobile,
      company: nullString(response.user.company),
      avatar: response.user.avatar,
      join_user_id: null,
      join_complex_id: null,
      ask_to_join: false
    };
  if (response.contact_id && response.contact)
    information.user = {
      user_id: response.contact.id,
      contact_id: response.contact.id,
      firstName: response.contact.first_name,
      lastName: response.contact.last_name,
      birthdate: response.contact && response.contact.birthdate ? response.contact.birthdate : "",
      email: response.contact.email,
      lastEmail: response.contact.email,
      country_code: response.contact.mobile ? response.contact.country_code : "",
      mobile: response.contact.mobile,
      company: nullString(response.contact.company),
      avatar: response.user.avatar,
      join_user_id: null,
      join_complex_id: null,
      ask_to_join: false
    };

  information.show = true;

  information.owner_id = response.owner_id;
  information.activity_id = response.activity_id;
  information.terrain_id = response.terrain_id.toString();
  information.duration_id = response.duration_id;
  information.date = response.date;
  information.start_hour = response.start_hour;
  information.info = response.infos;
  information.article_id = response.article_id;
  information.is_ticket = response.is_ticket;
  information.ticket_id = response.ticket_id;
  information.is_player = response.is_player;
  //return reservation Ticket if its has one
  information.ticket = response.ticket;

  information.end_hour = response.end_hour;
  information.hour_type_id = response.hour_type_id;
  information.price = nullString(response.price);
  information.codeRe = response.code_res;
  information.codeVideo = response.code_video;
  information.recurrence = response.recurrence;
  information.recurrence_days = response.recurrence_days === null ? [] : response.recurrence_days;
  information.choice_recurrence = response.choice_recurrence;
  information.cdn_video = response.cdn_video;
  information.conclusion = response.conclusion;
  information.is_advance = response.hasOwnProperty("is_advance")
    ? response.is_advance
    : response.advance_ticket
      ? response.advance_ticket
      : 0;
  information.advance = response.advance;
  information.is_leisure = response.is_leisure;
  information.games_nb = nullString(response.games_nb);
  information.games = response.is_leisure ?
    convertGame(response.games ? response.games : [], response)
    : [];
  information.advance_ticket = getAdvanceTicket();
  if (response.advance_ticket && response.advance_ticket.is_advance) {
    information.advance_ticket.id = response.advance_ticket.id;
    information.advance_ticket.article_id = response.advance_ticket.article_id;
    information.advance_ticket.amount = response.advance_ticket.amount;
    information.advance_ticket.payed_amount = response.advance_ticket.payed_amount;

    if (response.advance_ticket.payments)
      information.advance_ticket.payments = setPayments(response.advance_ticket.payments);
    if (response.advance_ticket.articles) {
      information.advance_ticket.articles = response.advance_ticket.articles;
    }

    information.advance_ticket.date = response.advance_ticket.date;
    information.advance_ticket.hour = response.advance_ticket.hour;
    information.advance_ticket.checkout_session_id =
      response.advance_ticket.checkout_session_id;
    information.advance_ticket.complex_id = response.advance_ticket.complex_id;
  }

  information.start_date_rec = response.start_date_rec;
  information.end_date_rec = response.end_date_rec;
  let competition = {
    competition_id: nullString(response.match?.competition_id),
    competition_day: nullString(response.match?.competition_day),
    competition_day_date: nullString(response.match?.competition_day_date),
    round: nullString(response.match?.round),
    team_a_id: nullString(response.match?.team_a_id),
    team_b_id: nullString(response.match?.team_b_id),
    group_name: nullString(response.match?.group_name),
    participants_nb: nullString(response.match?.participants_nb) ? response.match?.participants_nb : 0,
    level: nullString(response.match?.level),
    type: nullString(response.match?.type),
    sex: nullString(response.match?.sex),
    real_missing_participants_nb: nullString(response.match?.real_missing_participants_nb),
    missing_participants_nb: nullString(response.match?.missing_participants_nb),
    ball_size: nullString(response.match?.ball_size),
    price_per_participant: nullString(response.match?.price_per_participant),
    teams: []
  };

  let participants = {
    equipe1: {
      id: !!nullString(response.match?.team_a_id) ? response.match.team_a_id : 0,
      players: []
    },
    equipe2: {
      id: !!nullString(response.match?.team_b_id) ? response.match.team_b_id : 0,
      players: []
    }
  };

  if (!!response.match?.competition_id) {
    participants.equipe1.id = response.match?.team_a_id;
    participants.equipe2.id = response.match?.team_b_id;

    for (let i = 0; i < response.participants.length; i++) {
      let participant = response.participants[i];
      let p = playerFromUser(participant);

      if (p.team_id === participants.equipe1.id) participants.equipe1.players.push(p);
      else participants.equipe2.players.push(p);
    }
  } else {
    if (response.activity?.is_match) {
      for (let i = 0; i < response.participants.length; i++) {
        let participant = response.participants[i];
        // if(i===0)
        //     participants.equipe2.id=participant.pivot.team_id;

        let p = playerFromUser(participant);

        // is payed in the ticket
        p.payed =
          response.ticket && response.ticket.hasOwnProperty("participants")
            ? isParticipantPayed(p.user_id, response.ticket.participants)
            : false;

        if (participants.equipe2.id !== p.team_id) participants.equipe1.id = p.team_id;

        if (p.team_id === participants.equipe1.id) participants.equipe1.players.push(p);
        else participants.equipe2.players.push(p);
      }
    } else {
      for (let i = 0; i < response.participants.length; i++) {
        let participant = response.participants[i];
        let p = playerFromUser(participant);
        p.payed =
          response.ticket && response.ticket.hasOwnProperty("participants")
            ? isParticipantPayed(p.user_id, response.ticket.participants)
            : false;

        if (participants.equipe1.id === 0) participants.equipe1.id = p.team_id;

        participants.equipe1.players.push(p);
      }
    }
  }

  if (participants.equipe1.players.length < defaultParticipantsNb) {
    let messing = defaultParticipantsNb - participants.equipe1.players.length;
    for (let i = 0; i < messing; i++) participants.equipe1.players.push({ ...player });
  }

  if (participants.equipe2.players.length < defaultParticipantsNb) {
    let messing = defaultParticipantsNb - participants.equipe2.players.length;
    for (let i = 0; i < messing; i++) participants.equipe2.players.push({ ...player });
  }

  let selectedCompetition = response.match?.competition ? response.match?.competition : null;


  if (selectedCompetition && competition) {
    if (response.match?.team_a) competition.teams.push(response.match?.team_a);
    if (response.match?.team_b) competition.teams.push(response.match?.team_b);
  }


  filterResult = {
    ...filterResult,
    selectedActivity: response.activity,
    selectedTerrain: response.terrain,
    selectedCompetition,
    information: { ...filterResult.information, ...information },
    participants: { ...filterResult.participants, ...participants },
    competition
  };

  return filterResult;
};

export const ticketGroupStatus = (status = 0) => {
  if (status % 2 === 0) {
    return "#15A4FA";
  } else {
    return "#21B4CA";
  }
};

const convertGame = (games, reservation) => {

  let formattedGames = games.map((it, index) => {
    let newGames = { ...game };
    newGames.date = it.date;
    newGames.start_hour = it.start_hour;
    newGames.end_hour = it.end_hour;
    newGames.reservation_id = it.id;
    newGames.hasTicket = it.ticket !== null;
    return newGames;
  });

  let ownGame = {
    ...game
  };
  ownGame.reservation_id = reservation.id;
  ownGame.date = reservation.date;
  ownGame.start_hour = reservation.start_hour;
  ownGame.end_hour = reservation.end_hour;
  ownGame.reservation_id = reservation.id;
  ownGame.hasTicket = reservation.ticket !== null;
  ownGame.removable = false;


  return [ownGame, ...formattedGames];
};

export const isParticipantPayed = (user_id, participants) => {
  if (participants.length > 0) {
    let filterData = participants.filter((it) => it.user_id === user_id);
    if (filterData.length) {
      return !!filterData[0].status;
    }
  }

  return false;
};

export const articleToReservationResponse = (data, article) => {
  let filterResult = { ...data };

  let { information, competition } = filterResult;

  //information.show = true;

  information.activity_id = article.child ? article.child.activity_id : "";

  //
  competition.participants_nb = article && article.default_participants_nb ? nullStringNumber(article.default_participants_nb) : 1;
  competition.real_missing_participants_nb = article && article.default_participants_nb ? nullStringNumber(article.default_participants_nb) - 1 : 0;
  competition.missing_participants_nb = article && article.default_participants_nb ? nullStringNumber(article.default_participants_nb) - 1 : 0;
  competition.price_per_participant = article && article.default_participants_nb ? (parseFloat(article.total_price) / parseInt(competition.participants_nb)).toFixed(2) : 0;

  information.price = parseFloat(article.total_price);
  information.terrain_id = "";
  information.duration_id = article.duration_id;
  information.date = moment().format("YYYY-MM-DD");


  information.hour_type_id = article.hour_type_id;

  filterResult = {
    ...filterResult,
    selectedActivity: null,
    selectedTerrain: null,
    selectedDuration: null,
    selectedCompetition: null,
    information: { ...filterResult.information, ...information },
    participants: { ...filterResult.participants },
    competition
  };

  return filterResult;
};

const playerFromUser = (participant) => {
  return {
    ...player,
    user_id: participant.id,
    contact_id: participant.pivot.contact_id,
    firstName: participant.pivot.contact_id
      ? participant.pivot.contact.first_name
      : participant.firstName,
    lastName: participant.pivot.contact_id
      ? participant.pivot.contact.last_name
      : participant.lastName,
    email: participant.pivot.contact_id ? participant.pivot.contact.email : participant.email,
    country_code: participant.pivot.contact_id ? participant.pivot.contact.country_code : participant.country_code,
    mobile: participant.pivot.contact_id ? participant.pivot.contact.mobile : participant.mobile,
    sensor_id: participant.pivot.sensor_id,
    team_id: participant.pivot.team_id,
    discount_id: participant.pivot.discount_id
  };
};

export const conclusionColor = (conclusion, createdFrom) => {
  switch (conclusion) {
    case "A Venir":
      return "#21B4CA";
    case "A valider":
      return "#babbbc";
    case "Pas Venu":
      return "#4F4F4F";
    case "player":
      return "#F2C94C";
    case "booking":
      return "rgba(173, 6, 92, 0.9)";
    default:
      return "#72F286";
  }
};


export const conclusionClassName = (conclusion) => {
  switch (conclusion) {
    case "A Venir":
      return "venir";
    case "A valider":
      return "valider";
    case "Pas Venu":
      return "pas-venu";
    default:
      return "venu";
  }
};

export const conclusionTranslate = (conclusion) => {
  switch (conclusion) {
    case "A Venir":
      return "to-come-up";
    case "A valider":
      return "to-validate";
    case "Pas Venu":
      return "not-come";
    default:
      return "to-come";
  }
};

export const conclusionForPlayer = (reservation) => {
  if (reservation.created_from === 'booking' || reservation.is_player) {
    if (reservation.conclusion === "Pas Venu") return reservation.conclusion
    else
      return reservation.is_player ? 'player' : reservation.created_from;
  }

  return reservation.conclusion;
}

export const reservationInformationState = (owner_id = null, is_leisure = false) => {
  return {
    old_advance_id: null,
    information: {
      owner_id,
      reservation_id: null,
      is_ticket: 0,
      is_player: 0,
      show: false,
      isLoading: false,
      ticket: null,
      user: {
        user_id: null,
        contact_id: null,
        firstName: "",
        lastName: "",
        birthdate: "",
        email: "",
        lastEmail: "",
        mobile: "",
        country_code: DEFAULT_COUNTRY_CODE,
        company: "",
        join_user_id: null,
        join_complex_id: null,
        ask_to_join: false
      },
      info: "",
      activity_id: null,
      terrain_id: "",
      duration_id: "",
      date: "",
      start_hour: "",
      end_hour: "",
      hour_type_id: "",
      price: "",
      remise: "",
      codeRe: "",
      codeVideo: "",
      recurrence: 0,
      recurrence_days: [],
      choice_recurrence: 7,
      start_date_rec: "",
      end_date_rec: "",
      cdn_video: 1,
      is_advance: 0,
      is_leisure: 0,
      games_nb: 1,
      games: [{ ...game }],
      advance: "",
      advance_ticket: getAdvanceTicket(owner_id),
      advance_id: getAdvanceTicket(owner_id).advance_id, //to check (asma)
      conclusion: "A valider"
    },
    participants: {
      equipe1: {
        id: 0,
        players: new Array(is_leisure ? 1 : 5).fill(null).map(() => ({ ...player }))
      },
      equipe2: {
        id: 0,
        players: new Array(is_leisure ? 1 : 5).fill(null).map(() => ({ ...player }))
      }
    },
    history: {},
    competition: {
      competition_id: "",
      competition_day: "",
      competition_day_date: "",
      round: "",
      team_a_id: "",
      team_b_id: "",
      group_name: "",
      level: 0,
      type: 0,
      sex: 2,
      participants_nb: 1,
      real_missing_participants_nb: null,
      missing_participants_nb: null,
      ball_size: null,
      price_per_participant: null
    }
  };
};

export const resetReservationState = (owner_id = null, is_leisure = false) => ({
  modalAdd: false,
  selectedIndex: 0,
  teamIndex: 0,
  eventId: "",
  reservationForm: {
    leisure_ids: [],
    selectedTerrain: null,
    selectedCompetition: null,
    selectedActivity: null,
    checkReservation: false,
    checkParticipant: false,
    checkGame: false,
    selectedIndex: -1,
    ...reservationInformationState(owner_id, is_leisure)
  }
});

// TODO types:{
export const getAdvanceTicket = (owner_id = null) => ({
  id: null,
  amount: 0,
  payed_amount: 0,
  is_advance: 1,
  status: 1,
  complex_id: localStorage.getItem("complex_id"),
  user_id: "",
  article_id: "",
  owner_id: owner_id,
  checkout_session_id: localStorage.getItem("session_id"),
  remainder: "0",
  discount: "0",
  participants_nb: "0",
  articles_nb: "1",
  articles: [],
  payments: {
    articles: [
      {
        article_id: "",
        payment_type_id: null,
        credit_id: null,
        subscriber_id: null,
        subscriber_detail_id: null,
        credit_user_id: null,
        advance_id: null,
        amount: "",
        amount_ht: null,
        vat_amount: null,
        vat_id: null
      }
    ],
    participants: null,
    types: [{ payment_type_id: null, amount: null }]
  },
  discountTypes: { articles: [], participants: null },
  res_price: 0
});

const setPayments = (payments) => {
  return {
    articles: [
      {
        article_id: payments.articles.length === 0 ? "" : payments.articles[0].article_id,
        payment_type_id: payments.articles.length === 0 ? "" : payments.articles[0].payment_type_id,
        credit_id: null,
        subscriber_id: null,
        subscriber_detail_id: null,
        credit_account_id: null,
        credit_user_id: null,
        advance_id: null,
        amount: payments.articles.length === 0 ? "" : payments.articles[0].amount,
        amount_ht: payments.articles.length === 0 ? "" : payments.articles[0].amount_ht,
        vat_amount: payments.articles.length === 0 ? "" : payments.articles[0].vat_amount,
        vat_id: payments.articles.length === 0 ? "" : payments.articles[0].vat_id,
        credit_user: null
      }
    ],
    participants: null,
    types: [
      {
        payment_type_id:
          payments.types.length === 0
            ? ""
            : payments.types[0].hasOwnProperty("pivot")
              ? payments.types[0].pivot.payment_type_id
              : payments.types[0].payment_type_id,
        amount:
          payments.types.length === 0
            ? ""
            : payments.types[0].hasOwnProperty("pivot")
              ? payments.types[0].pivot.amount
              : payments.types[0].amount
      }
    ]
  };
};

export const ticketReservationStatus = (status) => {
  switch (status) {
    case -1:
      return "#FF7070"; //Réservation à payer
    case 1:
      return "rgba(173, 0, 255, 0.65)"; //Paiement partiel
    case 2:
      return "rgb(0, 102, 255,0.65)"; //Paiement en attente
    default:
      return "rgba(0, 184, 118, 0.65)"; //Paiement OK
  }
};

export const getPayments = async (article, reservation) => {
  let payments = {
    types: [],
    articles: [],
    participants: []
  };
  try {
    let paymentTypes = await paymentMethodService.paymentMethodService.index({
      page: 1,
      size: 100,
      search: null,
      status: [0]
    });

    let advanceId = paymentTypes.data.filter((it) => it.is_advance === 1);

    //advanceChecked
    if (reservation.advance_ticket) {
      let advanceAmount =
        parseFloat(reservation.advance_ticket.amount) -
        parseFloat(reservation.advance_ticket.payed_amount);
      let participant_nb = parseInt(article.default_participants_nb);

      //if participant division not excat
      let excess = parseFloat(article.total_price) -
        (parseFloat(article.price_per_participant) * parseInt(article.default_participants_nb));

      let total = parseFloat(article.total_price);
      let nb = Math.ceil(advanceAmount / article.price_per_participant);
      nb = nb < participant_nb ? nb : participant_nb;
      for (let i = 0; i < nb; i++) {
        let priceParticipants = parseFloat(article.price_per_participant);
        if (i === 0) {
          priceParticipants = parseFloat(parseFloat(article.price_per_participant) + parseFloat(excess));
        }
        let participant_amount =
          i < nb - 1 || advanceAmount >= nb * priceParticipants
            ? priceParticipants
            : advanceAmount - (((nb - 1) * article.price_per_participant) + parseFloat(excess));

        payments.participants.push({
          user_id: reservation.participants.length > i ? reservation.participants[i].id : null,
          user_index: article.id + "-" + (i + 1),
          payment_type_id: advanceId.length > 0 ? advanceId[0].id : null,
          article_id: article.id,
          credit_id: null,
          subscriber_id: null,
          subscriber_detail_id: null,
          credit_account_id: null,
          credit_user_id: reservation.advance_ticket.user_id,
          advance_id: reservation.advance_ticket.id,
          amount: parseFloat(participant_amount).toFixed(2),
          amount_ht: parseFloat(getHT(parseFloat(participant_amount), article.vat.value)).toFixed(3),
          vat_amount: (parseFloat(participant_amount) - parseFloat(getHT(parseFloat(participant_amount), article.vat.value))).toFixed(3),
          vat_id: article.vat.id
        });
      }

      payments.types.push({
        payment_type_id: advanceId.length > 0 ? advanceId[0].id : null,
        amount: total > advanceAmount ? advanceAmount : total
      });
      let article_amount = total > advanceAmount ? advanceAmount : total;
      payments.articles.push({
        article_id: article.id.toString(),
        payment_type_id: advanceId.length > 0 ? advanceId[0].id : null,
        credit_id: null,
        subscriber_id: null,
        subscriber_detail_id: null,
        credit_account_id: null,
        credit_user_id: reservation.advance_ticket.user_id,
        advance_id: reservation.advance_ticket.id,
        amount: article_amount,
        amount_ht: parseFloat(getHT(parseFloat(article_amount), article.vat.value)).toFixed(3),
        vat_amount: (
          parseFloat(article_amount) -
          parseFloat(getHT(parseFloat(article_amount), article.vat.value))
        ).toFixed(3),
        vat_id: article.vat.id
      });
    }
  } catch (e) {
    //
  }

  return payments;
};

// handle payments of an existing ticket
export const getPaymentsOfExistTicket = async (article, reservation, ticket) => {
  let payments = {
    ...ticket.payments
  };
  try {
    let paymentTypes = await paymentMethodService.paymentMethodService.index({
      page: 1,
      size: 100,
      search: null,
      status: [0]
    });

    let advanceId = paymentTypes.data.filter((it) => it.is_advance === 1);

    //advanceChecked
    if (reservation.advance_ticket) {
      let advanceAmount =
        parseFloat(reservation.advance_ticket.amount) -
        parseFloat(reservation.advance_ticket.payed_amount);
      let participant_nb = parseInt(article.default_participants_nb);
      let priceParticipants = parseFloat(article.price_per_participant);
      let selectedArticlePayedAmount = payments.articles.reduce(
        (acc, it) => parseFloat(it.amount) + acc,
        0
      );
      let total =
        parseFloat(article.total_price) -
        payments.articles.reduce((acc, it) => parseFloat(it.amount) + acc, 0);

      //let nb = Math.ceil(Math.abs(advanceAmount - selectedArticlePayedAmount) / priceParticipants);
      let payed = total >= advanceAmount ? advanceAmount : total;
      for (let i = 0; i < ticket.participants.length; i++) {
        if (payed <= 0) break;
        let parts = ticket.payments.participants.filter(
          (it) => it.user_index.toString() === ticket.participants[i].user_index
        );
        let tt = parts.reduce((acc, it) => parseFloat(it.amount) + acc, 0);
        if (parseFloat(tt) < priceParticipants) {
          let participant_amount =
            payed < priceParticipants - tt
              ? parseFloat(payed).toFixed(2)
              : parseFloat(priceParticipants - tt).toFixed(2);
          payments.participants.push({
            user_id: ticket.participants[i].user_id,
            user_index: ticket.participants[i].user_index,
            payment_type_id: advanceId.length > 0 ? advanceId[0].id : null,
            article_id: article.id,
            credit_id: null,
            subscriber_id: null,
            subscriber_detail_id: null,
            credit_account_id: null,
            credit_user_id: reservation.advance_ticket.user_id,
            advance_id: reservation.advance_ticket.id,
            amount: participant_amount,
            amount_ht: parseFloat(getHT(parseFloat(participant_amount), article.vat.value)).toFixed(3),
            vat_amount: (
              parseFloat(participant_amount) -
              parseFloat(getHT(parseFloat(participant_amount), article.vat.value))
            ).toFixed(3),
            vat_id: article.vat.id
          });
          if (payed < priceParticipants - tt) {
            payed = 0;
          } else {
            payed = payed - (priceParticipants - tt);
          }
        }
      }

      payments.types.push({
        payment_type_id: advanceId.length > 0 ? advanceId[0].id : null,
        amount:
          total > advanceAmount
            ? parseFloat(advanceAmount).toFixed(2)
            : parseFloat(total).toFixed(2)
      });
      let article_amount =
        total > advanceAmount ? parseFloat(advanceAmount).toFixed(2) : parseFloat(total).toFixed(2);
      payments.articles.push({
        article_id: article.id.toString(),
        payment_type_id: advanceId.length > 0 ? advanceId[0].id : null,
        credit_id: null,
        subscriber_id: null,
        subscriber_detail_id: null,
        credit_account_id: null,
        credit_user_id: reservation.advance_ticket.user_id,
        advance_id: reservation.advance_ticket.id,
        amount: article_amount,
        amount_ht: parseFloat(getHT(parseFloat(article_amount), article.vat.value)).toFixed(3),
        vat_amount: (
          parseFloat(article_amount) -
          parseFloat(getHT(parseFloat(article_amount), article.vat.value))
        ).toFixed(3),
        vat_id: article.vat.id
      });
    }
  } catch (e) {
    //
  }

  return payments;
};

export const reservationStatus = [
  {
    name: "reservation_validate",
    color: "#EEF1F7",
    is_recurrence: false
  },
  {
    name: "reservation_venu",
    color: "#21B4CA",
    is_recurrence: false
  },
  {
    name: "reservation_ok",
    color: "#72F286",
    is_recurrence: false
  },
  {
    name: "reservation_not_venu",
    color: "#2C2C2C",
    is_recurrence: false
  },
  {
    name: "reservation_created_player",
    color: "#F2C94C",
    is_recurrence: false
  },
  {
    name: "reservation_created_booking",
    color: "rgba(173, 6, 92, 0.9)",
    is_recurrence: false
  },
  {
    name: "reservation_unpaid",
    color: "#FF7070",
    is_recurrence: false
  },
  {
    name: "payment_on_wait",
    is_recurrence: false,
    color: "rgb(0, 102, 255,0.65)"
  },
  {
    name: "payment_partial",
    is_recurrence: false,
    color: "rgba(173, 0, 255, 0.65)"
  },
  {
    name: "payment_ok",
    is_recurrence: false,
    color: "rgba(0, 184, 118, 0.65)"
  },
  {
    name: "payment_invalid",
    is_recurrence: false,
    color: "#fff",
    fontColor: "#4F4F4F"
  },
  {
    name: "recurrence",
    is_recurrence: true,
    color: "rgba(255, 255, 255, 0.65)"
  }
];

// convert 01:00 to 25:00
export const formatDateAfterMidNight = (time) => {
  let newTime = time.split(":");
  let hours = newTime[0];
  if (parseInt(hours) >= 0) {
    hours = 24 + parseInt(newTime[0]);
  }

  return hours + ":" + newTime[1];
};

// convert 25:00=>01:00
export const reverseFormatDateAfterMidNight = (time, close) => {
  let newTime = time.split(":");
  let hours = newTime[0];

  if (parseInt(newTime[0]) >= 24 && parseInt(close.split(":")[0]) >= parseInt(newTime[0])) {
    hours = parseInt(newTime[0]) - 24;
  }

  if (hours.toString().length === 1) hours = "0" + hours.toString();

  return hours.toString() + ":" + newTime[1];
};

// convert 25:00=>01:00
export const moreFormatDateAfterMidNight = (time, close) => {
  let newTime = time.split(":");
  let hours = newTime[0];

  if (parseInt(newTime[0]) >= 24) {
    hours = parseInt(newTime[0]) - 24;
  }

  if (hours.toString().length === 1) hours = "0" + hours.toString();

  return hours.toString() + ":" + newTime[1];
};
// convert date 2021-01-20:24:00=>2021-01-21:00:00
export const dateConvert = (date, hour) => {
  if (parseInt(hour.split(":")[0]) >= 24) return moment(date).add(1, "day").format("YYYY-MM-DD");
  else return date;
};
// convert 2021-01-21:00:00 => date 2021-01-20:24:00
export const reverseDateConvert = (date, hour, open) => {
  if (
    parseInt(hour.split(":")[0]) >= 0 &&
    parseInt(open.split(":")[0]) >= parseInt(hour.split(":")[0])
  )
    return moment(date).subtract(1, "day").format("YYYY-MM-DD");
  else return date;
};


export const getResourceIds = (nb = 1) => {
  let arr = [];
  for (let i = 0; i < nb; i++) arr.push(i + 1);

  return arr;
};

// return the
export const checkParticipants = (user_id, participants, champ = "user_id") => {
  let result = [];
  for (let i = 0; i < participants.length; i++) {
    if (user_id === participants[i][champ] && participants[i][champ])
      result.push(participants[i]);
  }
  if (result.length < 2) return [];
  return result;
};

// return true if there are duplicated participants
export const checkForDuplicatedParticipant = (participants = [], champ = "user_id") => {
  let idSet = new Set();
  let parts = [];
  participants.forEach(it => {
    if (it[champ]) {
      idSet.add(it[champ]);
      parts.push(it[champ]);
    }
  });
  return (idSet.size !== parts.length);
};